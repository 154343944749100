import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pagination from "../components/pagination"
import { TAGS } from "../constants/tags"

const TagPill = ({tag, lang}) => {
  const { text, backgroundColor, fontColor } = TAGS[tag]

  return <span
   className="pill"
   style={{
     backgroundColor,
     color: fontColor
   }}
   >{text[lang]}</span>
}

const BlogIndex = ({ data, location, pageContext }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const avatar = data?.avatar?.childImageSharp?.fixed
  const { edges } = data.allMarkdownRemark
  const {
    currentPage,
    hasNextPage,
    hasPrevPage,
    prevPagePath,
    nextPagePath,
    tagId,
    lang,
  } = pageContext
  const currentPageInfo = currentPage > 0 ? `Page ${currentPage}` : ""

  if (edges.length === 0) {
    return (
      <Layout
        lang={lang}
        location={location}
        title={siteTitle}
        description={siteDescription}
        avatar={avatar}
      >
        <SEO title={siteTitle} />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  const tagTitle = tagId ? (lang === "fr" ? `Articles sur ${TAGS[tagId].text[lang]}` : `${TAGS[tagId].text[lang]} Articles`) : null
  return (
    <Layout
      lang={lang}
      location={location}
      title={siteTitle}
      description={siteDescription}
      avatar={avatar}
    >
      <SEO title={tagTitle || "Articles"} />
      {tagId && (
        <>
          <h2>
            {tagTitle}
          </h2>
          <p>{TAGS[tagId].description[lang]}</p>
        </>
      )}
      <ol className="blog-list">
        {edges.map(edge => {
          const title = edge.node.frontmatter.title || edge.node.fields.slug

          return (
            <li key={edge.node.fields.slug} className="blog-list-item">
              <div>
                <TagPill tag={edge.node.frontmatter.tag} lang={edge.node.frontmatter.lang} />
                <Link to={edge.node.fields.slug} itemProp="url" className="title">
                  <span itemProp="headline">{title}</span>
                </Link>
                <span><i className="time-to-read desktop"> - {edge.node.timeToRead} min read</i></span>
              </div>
              <div className="mobile time-to-read">
                <span className="date">{edge.node.frontmatter.date}&nbsp;</span>
                <span><i>- {edge.node.timeToRead} min read</i></span>
              </div>
              <strong className="date desktop">{edge.node.frontmatter.date}&nbsp;</strong>
            </li>
          )
        })}
      </ol>
      <div className="current-page-info">{currentPageInfo}</div>
      <Pagination
        prevPagePath={prevPagePath}
        nextPagePath={nextPagePath}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
      />
    </Layout>
  )
}

export default BlogIndex

export const query = graphql`
  query IndexTemplate($postsLimit: Int!, $postsOffset: Int!, $lang: String!, $filterByTagIds: [Int]) {
    site {
      siteMetadata {
        title
        description
      }
    }
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
    }
    allMarkdownRemark(
      limit: $postsLimit
      skip: $postsOffset
      filter: { frontmatter: { template: { eq: "post" }, lang: { eq: $lang }, subcategories: { in: $filterByTagIds } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM DD, YYYY")
            title
            description
            lang
            tag
          }
        }
      }
    }
  }
`
